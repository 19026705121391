<template>
    <div>
        <form @submit.prevent="updateOrder">
            <div class="form-group">
                <label for="inputEmail3" class="text-right">Select writer preferences</label>
                <div class="">
                    <div class="btn-group  " v-if="writer_levels.length > 0">
                        <button
                            type="button"
                            class="btn col-4 overflow-auto" 
                            v-for="(pref, index) in writer_levels" 
                            :key="index + 'wp'"
                            :class="writerLevelForm.writer_level === pref.id ? 'btn-success' : 'btn-outline-success'"
                            @click="activateWriterPref(pref)">
                            <div>
                            <span class="pref-title my-0 text-left">
                                {{ pref.title }}</span>
                            <span>{{ `(${pref.cost})` || "" }}</span>  
                            
                            <p class="my-0 text-left pref-desc  col-12" style="white-space: normal;word-wrap: break-word;">{{ pref.desc }}</p>
                            </div>
                        </button>
                    </div>

                    <button @click="doUpdateOrder" class="btn btn-success rounded--custom px-3 mt-4">Update Writer Preferences</button>
                </div>
            </div>
        </form>
    </div>
</template>


<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex";
export default {
    name: "WriterPreferences",
    computed: {
        ...mapGetters("setups", ["writer_levels"]),
        ...mapState("order", ["writerLevelForm", "price", "order"]),
    },
    methods: {
        ...mapMutations("order", ["updatePages", "_getOrder"]),
        ...mapActions("order", ["updateOrder", "_calculatePrice", "updateWriterPreferences"]),
        mapLevel() {
            this.writerLevelForm.writer_level = this.order.writer_level
        },
        doUpdateOrder() {
            this.updateWriterPreferences(this.writerLevelForm)
                .then((res) => {
                    // success
                    this.mapLevel()
                    this.$notify({
                        title: "Success",
                        text: res.data.Message || "Order updated",
                        style: "success",
                    });
                    // close modal
                    console.log(res);
                })
                .catch((err) => { 
                    this.$notify({
                        title: "Error",
                        text: err.response.data ? err.response.data.Message : "Error",
                        style: "danger",
                    });
                })

        },
        activateWriterPref(pref) {
            this.writerLevelForm.writer_level = pref.id
            // this._calculatePrice(this.writerLevelForm)
        },
    },

    mounted() {
        this.mapLevel()
    },
    beforeDestroy() {
        this.mapAdditionals()
    },

}
</script>