<template>
  <div class="form-group   row mb-2">
    <div class="col-12">

      <div class="file-upload-container">
        <!-- <input
          type="file"
          @change="uploadFiles"
          @dragend="uploadFiles"
          class="form-control border-0 file-upload"
          id="inputEmail3"
          placeholder="Choose files to upload"
          multiple="multiple"
        /> -->
        <div class="line-full">
          <div class="upload-file-part">
            <label class="content-upfile">
              <img src="/orders/upload.svg" class=" pb-1 " alt="icon">
              <span class="text mx-2">Upload your files
                <!-- <span class="hint" onmouseout="hide(this.lastChild)"><span onmouseover="show(this.nextSibling); return false"><img src="files/images/iconly/info_square.svg" alt="?" border="0"></span><div class="table1" style="width:300px; display:none">
                <p>Please provide instructor's files, course materials, article/book scans needed to complete the assignment (if applicable).</p>
              </div></span> -->
              </span>
              <a class="btn--upload px-3 py-2  small" style="cursor: pointer">Browse</a>
              <input 
              type="file"
              @change="uploadFiles"
              @dragend="uploadFiles" 
              multiple="multiple">
            </label>
            <div id="attachments">
                          </div>
            <div id="words_and_pages"></div>
          </div>
        </div>

        <div class="mb-1 mx-1" v-if="files.length > 0">
          <i v-for="(file, index) in files" :key="index">
            <span
              @click="deleteFile(file)"
              class="text-danger m-1 cursor-pointer delete-file"
              ><span class="icon-trash"></span
            ></span>
            {{ file.name }}
            <br />
          </i>
        </div>
      </div>
      
    </div>
    <!-- <div class="col-12">
      <p class="m-1 text-primary text-left">
        <span class="icon-help"></span> Drag and drop files or click to select
        files then upload
      </p>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      files: [],
    };
  },
  methods: {
    uploadFiles(event) {
      let files = event.target.files;

      Object.keys(files).forEach((file) => {
        this.files.push(files[file]);
      });

      this.$emit("input", this.files);
    },

    deleteFile(fileToDelete) {
      let files = this.files.filter((file) => file.name !== fileToDelete.name);
      this.files = files;
      this.$emit("input", this.files);
    },
  },
};
</script>

<style lang="scss" scoped>
$borderColor: #bebebe;
  $borderColorLight: #e0e0e0;
  $borderColorPrimary: #3695eb;
  $textColor: #244559;
//file upload
.file-upload {
  color: transparent;
  &:active,
  &:focus {
    box-shadow: none !important;
  }
}

.file-upload::-webkit-file-upload-button {
  visibility: hidden;
  box-shadow: none !important;
}

.file-upload::before {
  content: "Upload Files";
  position: absolute !important;
  left: 8px;
  right: 12px;
  top: 1px;
  bottom: -11px;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid $borderColor;
  border-radius: 3px;
  padding: 5px 8px;
  margin-bottom: 10px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
  color: $borderColorPrimary !important;

  &:focus,
  &:active {
    box-shadow: 0 0 0 4px rgb(33 153 232 / 25%) !important;
  }
}

.file-upload:hover::before {
  border-color: $borderColor;
}

.file-upload:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
</style>