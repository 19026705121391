<template>
  <div>
    <BaseLoading
      style="position: fixed; z-index: 3; top: 50%; right: 50%"
      v-if="is_loading"
    ></BaseLoading>
    <div class="order-info text-left">
      <div class="row">
        <div class="col-12 col-sm-8 table-responsive">
          <div class="order-details-item my-2 order-details-item-paper-detailes">
            <div class="order-details-header d-flex justify-content-between">
                <div class="title py-2 ">Order details</div>
            </div>                                     
            
          </div>

          <table
            class="table table-borderless table--custom text-left "
            style="font-size: 13px !important"
          >
            <tr>
              <td class="color-faded " >Topic</td>
              <td class="order-option-value " > {{ order.title || "" }}</td>
              <!-- <td></td> -->
            </tr>
            <tr>
              <td>Type of Paper</td>
              <td>{{ order.document_type_text || "" }}</td>
              <!-- <td></td> -->
            </tr>

            
            <tr>
              <td>Paper Format / Style</td>
              <td>{{ order.style_text || "" }}</td>
              <!-- <td></td> -->
            </tr>
            <tr>
              <td>Date Ordered</td>
              <td>{{ order.date_placed || "" }}</td>
              <td>
                <div class="settings-item_edit"><button  @click="toggleDeadline = !toggleDeadline" type="button" class="btn rounded-100 py-2 px-4 btn-outline-success ">Shorten Deadline</button></div>
              </td>
            </tr>
            <tr v-show="toggleDeadline" >
              <td>
                Shorten Deadline
              </td>
              <td>
                <div style="width:400px!important;display: block;"  class="settings-item_edit--modal" >
                <p> Choose the Shortest Deadline needed to complete your order. </p>
                  <form  id="client_change_name" method="post" class="validated_form" novalidate="novalidate">
                      <div  class="block_element my-2">
                          <v-select
                              label="desc"
                              v-model="orderForm.urgency" 
                              placeholder="Start typing to search"
                              @input="_calculatePrice"
                              :options="urgency"
                              :reduce="urg => urg.id"> 
                          </v-select>
                                              
                          <div class="settings-item_edit--modal-btn mt-3 d-flex align-items-center justify-content-end ">
                              <div class="btn-cancel" type="button" @click="toggleDeadline = !toggleDeadline" >Cancel</div>
                              <div type="button" class="btn-main text-center" @click="doUpdateOrder" ><span>Save</span><i class="fa fa-lg  fa-spin"></i></div>
                          </div>
                      </div>
                  </form>
                </div>
              </td>

            </tr>
            <tr>
              <td>Order Deadline</td>
              <td
                :class="
                  order.deadline && order.deadline.past_due ? 'text-danger' : 'text-success'
                "
                class="font-weight-bold"
              >
                {{ order.deadline && order.deadline.date || "" }}
              </td>
              <td
                :class="
                  order.deadline && order.deadline.past_due ? 'text-danger' : 'text-success'
                "
                class="font-weight-bold"
              >
                {{ order.deadline && order.deadline.past_due ? "Elapsed" : "" }}
                <span class="font-weight-black">{{ order.deadline && order.deadline.ellapsed || "" }}</span>
              </td>
            </tr>
            <tr>
              <td>Urgency</td>
              <td>
                <span
                  v-if="order.deadline"
                  :class="order.deadline.past_due ? 'text-danger' : 'text-success'"
                  >{{ order.urgency_text_hrs || "" }}</span
                >
              </td>
              <!-- <td></td> -->
            </tr>

            <tr>
              <td>Number of sources</td>
              <td>{{ order.no_sources || "0" }}</td>
              <!-- <td></td> -->
            </tr>
            <tr>
              <td>Writer Level</td>
              <td>{{ order.writer_level_desc || "0" }}</td>
              <td>
                <div class="settings-item_edit"><button  @click="togglePref = !togglePref" type="button" class="btn rounded-100 py-2 px-4 btn-outline-success ">Update Writer Preferences</button></div>
              </td>
            </tr>
            <tr v-show="togglePref" >
              <td>
                Update Writer Preferences
              </td>
              <td>
                <div style="width:500px!important;display: block;"  class="settings-item_edit--modal" >
                <p> Choose Your Writer  </p>
                  <form  id="client_change_name" method="post" class="validated_form" novalidate="novalidate">
                      <div  class="block_element my-2">
                        <div class="btn-group  " v-if="writer_levels.length > 0">
                            <button
                                type="button"
                                class="btn col-4 overflow-auto" 
                                v-for="(pref, index) in writer_levels" 
                                :key="index + 'wp'"
                                :class="writerLevelForm.writer_level === pref.id ? 'btn-success' : 'btn-outline-success'"
                                @click="activateWriterPref(pref)">
                                <div>
                                <span class="pref-title my-0 text-left">
                                    {{ pref.title }}</span>
                                <span>{{ `(${pref.cost})` || "" }}</span>  
                                
                                <p class="my-0 text-left pref-desc  col-12" style="white-space: normal;word-wrap: break-word;">{{ pref.desc }}</p>
                                </div>
                            </button>
                        </div>                   
                          <div class="settings-item_edit--modal-btn mt-3 d-flex align-items-center justify-content-end ">
                              <div class="btn-cancel" type="button" @click="togglePref = !togglePref" >Cancel</div>
                              <div type="button" class="btn-main text-center" @click="doUpdatePref" ><span>Save</span><i class="fa fa-lg  fa-spin"></i></div>
                          </div>
                      </div>
                  </form>
                </div>
              </td>

            </tr>

            <tr>
              <td>Additional Services</td>
              <td>
                <table class="table table-borderless">
                  <tr
                    v-for="(item, index) in order.additional_services_text"
                    :key="index + 'add'"
                  >
                    <td>{{ item[0] }}</td>
                    <hr />
                  </tr>
                </table>
              </td>
              <td>
                <div class="settings-item_edit"><button  @click="toggleAdditional = !toggleAdditional" type="button" class="btn rounded-100 py-2 px-4 btn-outline-success ">Additional Services</button></div>
              </td>
            </tr>
            <tr v-show="toggleAdditional" >
              <td>
                Additional Services
              </td>
              <td>
                <div style="width:500px!important;display: block;"  class="settings-item_edit--modal" >
                <p> Choose Your Writer  </p>
                  <form  id="client_change_name" method="post" class="validated_form" novalidate="novalidate">
                      <div  class="block_element my-2">
                        <div class="">
                            <div v-for="(service, index) in additional_services" :key="index" class="p-2 is-well mb-1">
                            <div class="custom-control custom-checkbox" >
                                <input v-model="orderExtraForm.additional_services" @change="_calculatePrice(orderExtraForm)" type="checkbox" class="custom-control-input border-1" :id="service.id" :value="service.id" :disabled="order.is_paid && is_selected(service.id)">
                                <label class="custom-control-label" :for="service.id">
                                    <strong class="my-0">
                                    {{service.title}}
                                    <span class="ml-4">+ ${{ service.cost || 0 }}</span>
                                    </strong>
                                <p class="my-1">{{service.desc}}</p>
                                    
                                </label>
                            </div>
                            </div>
                        </div>                 
                          <div class="settings-item_edit--modal-btn mt-3 d-flex align-items-center justify-content-end ">
                              <div class="btn-cancel" type="button" @click="toggleAdditional = !toggleAdditional" >Cancel</div>
                              <div type="button" class="btn-main text-center" @click="doUpdateServices" ><span>Save</span><i class="fa fa-lg  fa-spin"></i></div>
                          </div>
                      </div>
                  </form>
                </div>
              </td>

            </tr>

            <tr>
              <td>Spacing</td>
              <td>{{ order.spacing_text || "" }}</td>
              <!-- <td></td> -->
            </tr>

            <tr>
              <td>Number of Page(s)</td>
              <td>
                {{ order.pages || "" }} 
                <!-- / Aprox {{ no_of_words || "275" }} words -->
              </td>
              <td>
                <div class="settings-item_edit"><button  @click="togglePages = !togglePages" type="button" class="btn rounded-100 py-2 px-4 btn-outline-success ">Additional Pages</button></div>
              </td>
            </tr>
            <tr v-show="togglePages" >
              <td>
                Additional Pages
              </td>
              <td>
                <div style="width:400px!important;display: block;"  class="settings-item_edit--modal" >
                <p> Choose the number of additional pages needed to complete your order. </p>
                  <form  id="client_change_name" method="post" class="validated_form" novalidate="novalidate">
                      <div  class="block_element my-2">
                          <div class="input-group">
                          <span class="input-group-prepend">	
                              <button @click="updatePages(0)" class="btn btn-success px-4 btn-icon" type="button"><i class="icon-minus3"></i></button>
                          </span>
                          <input type="number"  name="pages" v-model="orderForm.pages" min="1" style="border-radius:0px!important;" class="form-control rounded-0 text-center" placeholder="">
                          <span class="input-group-append">
                              <button @click="updatePages(1)" class="btn btn-success px-4  btn-icon" type="button"><i class="icon-plus3"></i></button>
                          </span>
                          </div> 
                          <p class="my-2   " v-show="orderForm.pages">Aprox {{ orderForm.pages * 275 || '' }} additional words</p>
                                              
                          <div class="settings-item_edit--modal-btn mt-3 d-flex align-items-center justify-content-end ">
                              <div class="btn-cancel" type="button" @click="togglePages = !togglePages" >Cancel</div>
                              <div type="button" class="btn-main text-center" @click="doUpdateOrder" ><span>Save</span><i class="fa fa-lg  fa-spin"></i></div>
                          </div>
                      </div>
                  </form>
                </div>
              </td>

            </tr>

            <tr>
              <td>Number of Slide(s)</td>
              <td>{{ order.slides || "" }}</td>
              <td>
                <div class="settings-item_edit"><button  @click="toggleSlides = !toggleSlides" type="button" class="btn rounded-100 py-2 px-4 btn-outline-success ">Additional Slides</button></div>
              </td>
            </tr>
            <tr v-show="toggleSlides" >
              <td>
                Additional Slides
              </td>
              <td>
                <div style="width:400px!important;display: block;"  class="settings-item_edit--modal" >
                <p> Choose the number of additional slides needed to complete your order. </p>
                  <form  id="client_change_name" method="post" class="validated_form" novalidate="novalidate">
                      <div  class="block_element my-2">
                          <div class="input-group">
                          <span class="input-group-prepend">	
                              <button @click="updateSlides(0)" class="btn btn-success px-4 btn-icon" type="button"><i class="icon-minus3"></i></button>
                          </span>
                          <input type="number"  name="pages" v-model="orderForm.slides" min="1" style="border-radius:0px!important;" class="form-control rounded-0 text-center" placeholder="">
                          <span class="input-group-append">
                              <button @click="updateSlides(1)" class="btn btn-success px-4  btn-icon" type="button"><i class="icon-plus3"></i></button>
                          </span>
                          </div> 
                                              
                          <div class="settings-item_edit--modal-btn mt-3 d-flex align-items-center justify-content-end ">
                              <div class="btn-cancel" type="button" @click="toggleSlides = !toggleSlides" >Cancel</div>
                              <div type="button" class="btn-main text-center" @click="doUpdateOrder" ><span>Save</span><i class="fa fa-lg  fa-spin"></i></div>
                          </div>
                      </div>
                  </form>
                </div>
              </td>

            </tr>
            <tr>
              <td>Number of Chart(s)</td>
              <td>{{ order.charts || "" }}</td>
              <td>
                <div class="settings-item_edit"><button  @click="toggleCharts = !toggleCharts" type="button" class="btn rounded-100 py-2 px-4 btn-outline-success ">Additional Charts</button></div>
              </td>
            </tr>
            <tr v-show="toggleCharts" >
              <td>
                Additional Charts
              </td>
              <td>
                <div style="width:400px!important;display: block;"  class="settings-item_edit--modal" >
                <p> Choose the number of additional charts needed to complete your order. </p>
                  <form  id="client_change_name" method="post" class="validated_form" novalidate="novalidate">
                      <div  class="block_element my-2">
                          <div class="input-group">
                          <span class="input-group-prepend">	
                              <button @click="updateCharts(0)" class="btn btn-success px-4 btn-icon" type="button"><i class="icon-minus3"></i></button>
                          </span>
                          <input type="number"  name="pages" v-model="orderForm.charts" min="1" style="border-radius:0px!important;" class="form-control rounded-0 text-center" placeholder="">
                          <span class="input-group-append">
                              <button @click="updateCharts(1)" class="btn btn-success px-4  btn-icon" type="button"><i class="icon-plus3"></i></button>
                          </span>
                          </div> 
                                              
                          <div class="settings-item_edit--modal-btn mt-3 d-flex align-items-center justify-content-end ">
                              <div class="btn-cancel" type="button" @click="toggleCharts = !toggleCharts" >Cancel</div>
                              <div type="button" class="btn-main text-center" @click="doUpdateOrder" ><span>Save</span><i class="fa fa-lg  fa-spin"></i></div>
                          </div>
                      </div>
                  </form>
                </div>
              </td>

            </tr>

            <tr
              v-for="(extra, index) in order.extras"
              :key="index + 'oextras'"
              :class="extra.paid !== '0' ? 'alert-success' : 'alert-danger'"
            >
              <td>{{ extra.name || "" }}</td>
              <td>${{ extra.cost || 0 }}</td>
              <td>
                <button
                  v-if="extra.paid !== '0'"
                  class="ml-1 btn btn-sm bg-teal"
                >
                  Paid
                </button>
                <span
                  v-else
                  class="d-flex justify-content-center align-items-center"
                >
                  Unpaid
                  <button
                    @click="deleteExtra(extra.id)"
                    class="mx-1 btn btn-sm btn-outline-danger"
                  >
                    Delete
                  </button>
                </span>
              </td>
            </tr>
            <tr class="alert alert-primary border-0">
              <td class="font-weight-bold">Extras Total</td>
              <td class="font-weight-bold" style="font-size: 18px;">${{ order.extras_total || 0 }}</td>
              <!-- <td></td> -->
            </tr>
            <tr>
              <td class="font-weight-bold">Total Order Cost</td>
              <td class="font-weight-bold" style="font-size: 18px;">${{ order.cost }}</td>
              <!-- <td></td> -->
            </tr>
            <tr class="alert alert-success border-0">
              <td class="font-weight-bold">Paid</td>
              <td class="font-weight-bold" style="font-size: 18px;">${{ order.total_paid || 0 }}</td>
              <!-- <td></td> -->
            </tr>
            <tr class="alert alert-danger border-0">
              <td class="font-weight-bold">Balance</td>
              <td class="font-weight-bold" style="font-size: 18px;">${{ order.balance || 0 }}</td>
              <!-- <td></td> -->
            </tr>
          </table>

          <div class="order-details-item my-4 order-details-item-paper-detailes">
            <div class="order-details-header d-flex justify-content-between">
                <div class="title py-2 ">Paper details</div>
            </div>                                     
            <div class="order-details-block">
                <div class="order-option-value order-option-instructions" data-full-text="In ducimus mollitia In ducimus mollitia In ducimus mollitia <span class='see-less-instructions'>See less</span>" data-truncate-text="In ducimus mollitia In ducimus mollitia In ducimus mollitia"> 
                  <pre style="padding: 4px !important">                 
                   <span v-html="order.description"></span>
                  </pre>
                </div>
            </div>
          </div>
        </div>


        

      
      </div>

      <!-- Modal -->
      <BaseModal :title="activeComponent.title">
        <component :is="activeComponent.component"></component>
      </BaseModal>
    </div>
  </div>
</template>


<script>
import pages_png from "../../assets/global_assets/images/ordericons/pages.png";
import slides_png from "../../assets/global_assets/images/ordericons/slides.png";
import charts_png from "../../assets/global_assets/images/ordericons/charts.png";
import deadline_png from "../../assets/global_assets/images/ordericons/deadline.png";
import progressive_png from "../../assets/global_assets/images/ordericons/progressive.png";
import preferences_png from "../../assets/global_assets/images/ordericons/preferences.png";

import AdditionalPages from "../order/modals/AdditionalPages.vue";
import AdditionalServices from "../order/modals/AdditionalServices.vue";
import AdditionalCharts from "../order/modals/AdditionalCharts.vue";
import AdditionalSlides from "../order/modals/AdditionalSlides.vue";
import ShortenDeadline from "../order/modals/ShortenDeadline.vue";
import WriterPreferences from "../order/modals/WriterPreferences.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
export default {
  props: {
    order: {
      type: Object,
    },
  },
  components: {
    AdditionalPages,
    AdditionalServices,
    AdditionalCharts,
    AdditionalSlides,
    ShortenDeadline,
    WriterPreferences,
  },
  computed: {
    ...mapGetters("setups", ["number_of_pages","additional_services","writer_levels", "spacing", "urgency"]),
    ...mapState("order", ["orderForm", "writerLevelForm", "order", "orderExtraForm", "price",]),
    // ...mapGetters("setups", ["urgency"]),


    // no_of_words() {
    //   return (
    //     275 *
    //       this.order.no_pages *
    //       this.spacing.find((x) => x.id == this.order.spacing).factor || 1
    //   );
    // },
  },
  data() {
    return {
      is_loading: false,
      activeComponent: {},
      togglePages:false,
      toggleSlides:false,
      toggleCharts:false,
      toggleDeadline:false,
      toggleAdditional:false,
      togglePref:false,
      additionalPage: [
        {
          title: "Request Additional Pages",
          icon: pages_png,
          component: "AdditionalPages",
        }
      ],
      additionalSlides: [
        {
          title: "Request Powerpoint slides",
          icon: slides_png,
          component: "AdditionalSlides",
        }
      ],
      additionalCharts: [
        {
          title: "Request Additional Charts",
          icon: charts_png,
          component: "AdditionalCharts",
        }
      ],
      additionalDeadline: [
        
        {
          title: "Extend Deadline",
          icon: deadline_png,
          component: "ShortenDeadline",
        }
      ],
      additionalPreferences: [
        {
          title: "Update Writer Preferences",
          icon: preferences_png,
          component: "WriterPreferences",
        }
      ],
      additionalServices: [
        {
          title: "Additional Services",
          icon: progressive_png,
          component: "AdditionalServices",
        },
      ],

      additionalItems: [
        {
          title: "Additional Pages",
          icon: pages_png,
          component: "AdditionalPages",
        },
        {
          title: "Powerpoint slides",
          icon: slides_png,
          component: "AdditionalSlides",
        },
        {
          title: "Charts",
          icon: charts_png,
          component: "AdditionalCharts",
        },
        {
          title: "Shorten Deadline",
          icon: deadline_png,
          component: "ShortenDeadline",
        },
        {
          title: "Update Writer Preferences",
          icon: preferences_png,
          component: "WriterPreferences",
        },
        {
          title: "Additional Services",
          icon: progressive_png,
          component: "AdditionalServices",
        },
      ],
    };
  },

  methods: {
    ...mapActions("order", ["deleteExtras"]),
    ...mapMutations("order", ["updatePages", "updateSlides", "updateCharts", "_getOrder"]),
    ...mapActions("order", ["updateOrder","addOrderExtras","updateWriterPreferences",]),
    deleteExtra(id) {
      this.is_loading = true;
      this.deleteExtras(id)
        .then((res) => {
          console.log(res);
          // this,_getOrder(this.order.id)
          // alert
          this.$notify({
            title: "Success",
            text: "Order extra deleted successfully!",
          });
        })
        .catch((err) => {
          console.log(err);
          // alert
          this.$notify({
            title: "Error",
            text: err.response.data ? err.response.data.Message : "Error",
            style: "danger",
          });
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
    is_selected(service_id){
           return this.order.additional_services.includes(service_id)
        },
    mapLevel() {
            this.writerLevelForm.writer_level = this.order.writer_level
        },
    doUpdateOrder() {
      this.is_loading = true;

        this.updateOrder(this.orderForm)
            .then((res) => {
                // success
                this.$notify({
                    title: "Success",
                    text: res.data.Message || "Updated successfully",
                    style: "success",
                });
                this.togglePages == false

                // close modal
                console.log(res);
            })
            .catch((err) => { 
                console.log(err)
                this.$notify({
                    title: "Error",
                    text: err.response.data ? err.response.data.Message : "Error",
                    style: "danger",
                });
            })
            .finally(() => {
          this.is_loading = false;
        });


    },
    doUpdatePref() {
      this.is_loading = true;

        this.updateWriterPreferences(this.writerLevelForm)
            .then((res) => {
                // success
                this.mapLevel()
                this.$notify({
                    title: "Success",
                    text: res.data.Message || "Order updated",
                    style: "success",
                });
                // close modal
                console.log(res);
            })
            .catch((err) => { 
                this.$notify({
                    title: "Error",
                    text: err.response.data ? err.response.data.Message : "Error",
                    style: "danger",
                });
            }).finally(() => {
          this.is_loading = false;
        });

    },
    doUpdateServices() {
      this.is_loading = true;

        this.orderExtraForm.order_id = this.order.id
        this.addOrderExtras(this.orderExtraForm)
            .then((res) => {
                // success
                this.$notify({
                    title: "Success",
                    text: res.data.Message || "Additional services successifully added",
                    style: "success",
                });
                // close modal
            })
            .catch((err) => { 
                this.$notify({
                    title: "Error",
                    text: err.response.data ? err.response.data.Message : "Error",
                    style: "danger",
                });
            }).finally(() => {
          this.is_loading = false;
        });

    },
    activateWriterPref(pref) {
        this.writerLevelForm.writer_level = pref.id
        // this._calculatePrice(this.writerLevelForm)
    },
  },

  mounted() {
        this.mapLevel()
    },
    // beforeDestroy() {
    //     this.mapAdditionals()
    // },
};
</script>

<style lang="scss" scoped>
.table td {
  padding:  8px !important;
  max-width: 300px;
}
.is-additional {
  cursor: pointer;
  img {
    margin: 4px;
  }
  &:hover {
    img {
      transform: scale(1.05);
      transition: 0.3s all ease-in-out;
    }
  }
}
</style>
