<template>
    <div>
        <div class="form-group row">
            <label for="inputEmail3" class="text-left custom--p col-sm-12 col-form-label">Deadline / Urgency</label>
            <div class="col-sm-10">
                <v-select
                    label="desc"
                    v-model="orderForm.urgency" 
                    placeholder="Start typing to search"
                    @input="_calculatePrice"
                    :options="urgency"
                    :reduce="urg => urg.id"> 
                </v-select>
                <button @click="doUpdateOrder" class="btn btn-success rounded--custom  mt-4">Update Deadline</button>
            </div>


        </div>
    </div>
</template>


<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
export default {
    name: "AdditionalSlides",
    computed: {
        ...mapGetters("setups", ["urgency"]),
        ...mapState("order", ["orderForm", "order"])
    },
    methods: {
        ...mapMutations("order", ["updateSlides", "_calculatePrice", "_getOrder"]),
        ...mapActions("order", ["updateOrder"]),
        doUpdateOrder() {
            this.updateOrder(this.orderForm)
                .then((res) => {
                    // success
                    this.$notify({
                        title: "Success",
                        text: res.data.Message || "Order deadline updated",
                        style: "success",
                    });
                    // close modal
                    console.log(res);
                })
                .catch((err) => { 
                    console.log(err)
                    this.$notify({
                        title: "Error",
                        text: err.response.data ? err.response.data.Message : "Error",
                        style: "danger",
                    });
                })

        }
    }
}
</script>