<template>
    <div>
        <p class="custom--p mb-2" >
            Choose the number of additional pages needed to complete your order. 
        </p>

        <div class="form-group row">
        <!-- <label class="col-form-label color-faded col-sm-12 text-left">Additional Pages</label> -->
        <div class="col-sm-6 col-10 ">
            <div class="input-group">
            <span class="input-group-prepend">	
                <button @click="updatePages(0)" class="btn btn-success px-4 btn-icon" type="button"><i class="icon-minus3"></i></button>
            </span>
            <input type="number"  name="pages" v-model="orderForm.pages" min="1"  class="form-control text-center" placeholder="">
            <span class="input-group-append">
                <button @click="updatePages(1)" class="btn btn-success px-4  btn-icon" type="button"><i class="icon-plus3"></i></button>
            </span>
            </div>
            <p class="my-2   " v-show="orderForm.pages">Aprox {{ orderForm.pages * 275 || '' }} additional words</p>
            <button class="btn btn-success rounded--custom  mt-4" @click="doUpdateOrder">Add Pages</button>
        </div>
        </div>
    </div>
</template>


<script>
import { mapMutations, mapState, mapActions } from "vuex";
export default {
    name: "AdditionalPages",
    computed: {
        ...mapState("order", ["orderForm", "order"])
    },
    methods: {
        ...mapMutations("order", ["updatePages", "_getOrder"]),
        ...mapActions("order", ["updateOrder"]),
        doUpdateOrder() {
            this.updateOrder(this.orderForm)
                .then((res) => {
                    // success
                    this.$notify({
                        title: "Success",
                        text: res.data.Message || "Additional pages added successifully",
                        style: "success",
                    });
                    // close modal
                    console.log(res);
                })
                .catch((err) => { 
                    console.log(err)
                    this.$notify({
                        title: "Error",
                        text: err.response.data ? err.response.data.Message : "Error",
                        style: "danger",
                    });
                })

        }
    }
}
</script>